export default {
  translation: {
    event: {
      about: "Om",
      accepted: "accepteres",
      achievedDiscount: "Du har opnået {{discount}} rabat",
      addDiscountCode: "Tilføj rabatkode",
      addedVATToTicketPrice: "Billetprisen tillæges {{vat}} moms",
      addGiftCardCode: "Tilføj gavekortkode",
      additionalProductsFees: "Tillægsproduktgebyr",
      addRefundProtectionToOrder: "Jeg ønsker afbestillingsbeskyttelse, og accepterer disse",
      addressForInvoice: "Adresse for faktura",
      amount: "Antal",
      and: "og",
      apply: "Anvend",
      applyNoShowFee: "No-Show gebyr er kun pålagt, hvis du ikke deltager",
      back: "Tilbage",
      backToCheckinCounter: "Tilbage til check-in skranken",
      backToEventPage: "Tilbage til event side",
      booth: "Stand",
      businessOrOrganisationName: "Navn på virksomhed eller organisation",
      buyGiftCards: "Køb gavekort",
      buyExtras: "Køb tillæg",
      buyTickets: "Køb billetter",
      calendarNotAvailable: "Kalenderen blev ikke vist. Opdater venligst siden.",
      cancel: "Aflys",
      canceledEvent: "Eventet er aflyst.",
      cancelledNotification: "Din underretning er annulleret",
      cancelledPayment: "Betalingen er annulleret",
      cancelledRegistration: "Registreringen er annulleret",
      checkout: "Bestil",
      checkSpamFolder: "Husk at tjekke din spammappe",
      chooseOnePriority: "Du kan kun vælge én prioritet per bolig",
      chooseOneType: "Du kan kun vælge én type per sæde",
      choosePaymentMethod: "Vælg betalingsmetode",
      chooseTickets: "Vælg billetter",
      chooseYourSpot: "Vælg plads",
      city: "By",
      сlimateOffsetting: "Klimakompensation i samarbejde med Climaider.",
      co2Offset: "CO2-kompenser for din deltagelse - 5,00 DKK per billet",
      co2Offsetting: "CO2-kompensation",
      comments: "Kommentarer",
      companyOrInstitutionName: "Navn på virksomhed/institution",
      confirmationSentTo: "Bekræftelsen er sendt til",
      congratulations: "Tillykke",
      connectingToTheEventError: "Der skete en fejl",
      contactInformation: "Kontaktinformation",
      contactOurSupport: "Tøv ikke med at kontakte vores support",
      continue: "Fortsæt",
      continueRegistration: "Fortsæt registrering",
      contributionFor: "Hvad går mit bidrag til?",
      cookieAndPrivacyPolicy: "cookie- og privatlivspolitik",
      counteractTheClimateEffects: "For at udligne klimaeffekterne forbundet med min deltagelse i eventet, vil jeg gerne bidrage med 5,00 DKK per billet.",
      countFreePassesAvailable: "Du har {{count_left}} fribilletter tilgængelige",
      createEvent: "Opret event",
      customerInfo: "Køberinformation",
      CVRNumber: "CVR Nummer",
      dateAndTime: "Dato og tidspunkt",
      discountCode: "Rabatkode",
      discountCodeApplied: "Rabatkode tilføjet",
      doubleCheckEmailSpellingAllFine: "Alt ser fint ud, men dobbelttjek venligst emailen for stavefejl.",
      doubleCheckEmailSpellingSomethingWrong: "Noget ser forkert ud, dobbelttjek venligst emailen for stavefejl.",
      downloadGiftCard: "Hent gavekort",
      downloadGiftCards: "Hent gavekort",
      downloadSeasonTicket: "Hent sæsonkort",
      downloadSeasonTickets: "Hent sæsonkort",
      downloadTicket: "Hent billet",
      downloadTickets: "Hent billetter",
      EANNumber: "EAN nummer",
      emailAboutAvailableSpots: "Hvis pladser bliver ledige så vil du modtage en email.",
      emailAboutAvailableTickets: "Hvis billetter bliver ledige så vil du modtage en email.",
      enjoy: "God fornøjelse!",
      enjoyTheEvent: "Nyd arrangementet",
      enrollToWaitingList: "Tilmeld dig til venteliste",
      errorMessage: "Der skete en fejl",
      errorOccurred: "An error occurred, please try again",
      errorTryAgain: "Der skete en fejl. Prøv igen.",
      eventInformation: "Event-information",
      eventIsSoldOut: "DETTE EVENT ER UDSOLGT",
      eventNotFound: "Event not found",
      eventTemporarilySoldOut: "Dette event er midlertidigt udsolgt.",
      expired: "Udløbet",
      expiredEvent: "Dette event er udløbet.",
      extraInformationForEANPayment: "Extra information for EAN betalinger",
      extras: "Tilføjelser",
      failedFetchingQuote: "Kunne ikke hente tilbud.",
      fee: "gebyr",
      fetchingQuote: "henter tilbud",
      fewTicketsLeft: "Få billetter tilbage",
      findOnFacebook: "Find os på Facebook",
      firstName: "Fornavn",
      free: "Gratis",
      freePassesAvailable: "Du har fribilletter tilgængelige",
      fullRefundWithRefundProtection: "Med afbestillingsservice via Booking Protect kan du i visse tilfælde få din ordre refunderet (eks. ved akut sygdom eller dødsfald i den nære familie). Bemærk, at der kræves dokumentation ved ansøgning om refundering.",
      gettingAdditionalProducts: "Henter tillægsprodukter",
      giftCard: "Gavekort",
      giftCardApplied: "Gavekortkode tilføjet",
      giftCardCanBeUsedForEvents: "Gavekortet kan nu benyttes til events arrangeret af",
      giftCardCode: "Gavekortkode",
      giftCards: "Gavekort",
      giftCardsCanBeUsedForEvents: "Gavekortene kan nu benyttes til events arrangeret af",
      giftCardsWillBeSentTo: "Dine gavekort vil blive sendt til",
      giftCardWillBeSentTo: "Dit gavekort vil blive sendt til",
      goToCheckinCounter: "Gå til check-in skranken.",
      goToGiftCards: "Gå til gavekort",
      goToRegistration: "Gå til registrering",
      goToTickets: "Gå til billetter",
      guest: "Gæst",
      haveBeenSentTo: "{{items}} er sendt til",
      helpCenter: "Hjælpecenter",
      hereofVAT: "Heraf Moms",
      hideSummary: "Se mindre",
      houseNumber: "Hus nr. ",
      housing: "Bolig",
      housingSummary: "Din boligoversigt",
      iAgreeTo: "Jeg accepterer",
      ifHaventReceivedAnSMS: "Hvis du ikke har modtaget en SMS, så gå til check-in skranken og sig dit navn eller telefonnummer",
      informationPerReservation: "Information pr. reservation",
      informationPerTicket: "Information pr. billet",
      invoiceInformation: "Faktura information",
      invoiceOrEAN: "Faktura/EAN",
      lastName: "Efternavn",
      left: "tilbage",
      linkToYourTickets: "med et link til dine billetter",
      loadingEvents: "Henter arrangementer",
      location: "Lokation",
      login: "Log ind",
      memberCode: "Medlemsnummer",
      multipleStartingTimes: "Flere starttider",
      multipleStartTimes: "Eventet har flere starttider",
      newPurchase: "Nyt køb",
      newRegistration: "Ny registrering",
      news: "Nyheder",
      no: "Nej",
      noEvents: "Ingen events",
      noEventsFor: "Ingen events for",
      noShowFee: "med {{price}} no-show fee på",
      notAutomaticallyTakeToPaymentWindow: "Hvis du ikke automatisk føres til betalingsvinduet, skal du klikke på knappen nedenfor",
      noTicketsAdded: "No tickets have been added",
      notifyAboutAvailableSpots: "Ønsker du at blive underrettet på mail, hvis pladser til dette event bliver ledige?",
      notifyAboutAvailableTickets: "Ønsker du at blive underrettet på mail, hvis billetter til dette event bliver ledige?",
      numberOfTickets: "Antal billetter",
      offsetAmountWillNotBeReimbursed: "Kompensationsbeløbet tilbagebetales ikke ved evt. refundering af billetter.",
      openPaymentWindow: "Et øjeblik – du føres til betalingsvinduet",
      orderNumber: "Ordrenummer",
      orderSummary: "Din Ordreoversigt",
      outOf2: "ud af 2.",
      pageWillGoBack: "Denne side vil gå tilbage til start om 10 sekunder.",
      pay: "Betal",
      payByInvoiceRemark: "BEMÆRK Du har valgt at betale via faktura. Du vil inden længe modtage en email fra arrangører vedr. dette.",
      payForTickets: "Betal for dine billetter",
      payment: "Betaling",
      paymentByEANNumber: "Betaling med EAN nummer er forbeholdt danske offentlige organisationer og institutioner. EAN betaling vil blive opkrævet via faktura",
      payWithCreditCard: "Betal med kreditkort",
      payWithMobilePay: "Betal med MobilePay",
      payWithStripe: "Betal sikkert med Stripe",
      phone: "Telefon",
      placeOrder: "Angiv bestilling",
      postCode: "Postnummer",
      preparingOrder: "Forbereder din ordre",
      priceIncludesVAT: "Pris inkluderer {{vat}} moms",
      privacyPolicy: "Privatlivspolitik",
      protectOrder: "Jeg vil gerne beskytte min ordre",
      provideCustomerInfo: "Indtast venligst køberinformation.",
      provideRegistrationInfo: "Indtast venligst dine registreringsoplysninger",
      purchaseSuccessful: "Købet er gennemført",
      quantity: "Antal",
      readMoreHere: "Læs mere her",
      receiveNewsFrom: "Modtag nyheder fra {{name}}",
      receiveNewslettersWithUpdates: "Jeg ønsker at modtage nyhedsbreve på e-mail med information om kommende arrangementer, tilbud og produkter fra {{name}}. Læs privatlivspolitik om behandlingen <5>her</5>. Du har altid ret til at trække dit samtykke tilbage.",
      receiveSMSWithTicketLink: "Jeg vil gerne modtage en SMS med et link direkte til min billet.",
      receiverInformation: "Modtagerinformation",
      referenceEAN: "Reference EAN nummer for EAN-betaling",
      refreshToReserveAgain: "Din checkud tid er udløbet. Opdater venligst for at reservere igen.",
      refundProtection: "Afbestillingsservice",
      refundProtectionNotAvailable: "Beklager, sikringen af refundering er ikke tilgængelig lige nu.",
      register: "Afslut registrering",
      registerHousesWarningMessage: "Du kan registrere to boliger, men har kun valgt at registrere én. Er du sikker på, at du vil fortsætte registreringen med kun én bolig?",
      registrationFor: "Registrering til",
      registrationInfo: "Registreringsoplysninger",
      registrationNumber: "Registreringsnummer",
      registrationsOpen: "Der åbnes for registreringer den",
      registrationSuccessful: "Din registrering lykkedes",
      registrationSummary: "Din Registreringsoversigt",
      repeat: "Gentag",
      requestErrorTryAgain: "Der skete en fejl. Prøv igen.",
      resendMyTicket: "Gensend min billet",
      reservation: "reservat",
      reservationNumber: "Reservation nr.",
      reservations: "reservationer",
      reservationWillBeLost: "Er du sikker på, at du vil annullere? Din reservation vil gå tabt.",
      reserve: "Reserver",
      row: "Række",
      save: "Gem",
      seasonTicketsWillBeSentTo: "Sæsonkortene vil blive sendt til",
      seasonTicketWillBeSentTo: "Sæsonkortet vil blive sendt til",
      seat: "Sæde",
      seatingCancel: "Annuller",
      seatingInformation: "Siddepladser",
      seatingSelectionInvalid: "Dit valg af siddepladser er ugyldigt. Gennemgå dit valg.",
      section: "Sektion",
      secureSeasonTicket: "Du har sikret dig et sæsonkort til",
      secureSeasonTickets: "Du har sikret dig sæsonkort til",
      secureTicket: "Du har sikret dig billet til",
      secureTickets: "Du har sikret dig billetter til",
      selectAdditionalProducts: "Her kan du tilføje tillægsprodukter til dine billetter",
      selectDay: "Vælg en dato",
      selectHousePriority: "Vælg din boligprioritet",
      selectItemVariation: "Vælg variation af vare",
      sendGiftCard: "Du kan sagtens lukke vinduet - vi sender gavekortet til din email.",
      sendGiftCards: "Du kan sagtens lukke vinduet - vi sender gavekortene til din email.",
      sendSeasonTicket: "Du kan sagtens lukke dette vindue - vi sender sæsonkortet til din email.",
      sendSeasonTickets: "Du kan sagtens lukke dette vindue - vi sender sæsonkortene til din email.",
      sendTicket: "Du kan sagtens lukke dette vindue - vi sender billetten til din email.",
      sendTickets: "Du kan sagtens lukke dette vindue - vi sender billetterne til din email.",
      sentMessageToFix: "UPS noget gik galt! Vi har sendt en besked til vores tech team så de kan fikse det",
      services: "Tjenester",
      serviceVAT: "Service Moms",
      showSeasonTicketOnSmartphone: "Du behøver ikke at printe det ud. Vis det blot fra din smartphone hvis muligt",
      showSeasonTicketsOnSmartphone: "Du behøver ikke at printe dem ud. Vis dem blot fra din smartphone hvis muligt",
      showTicketOnSmartphone: "Du behøver ikke at printe din billet ud. Vis den blot fra din smartphone hvis muligt",
      showTicketsAtCheckinCounter: "Gå venligst til check-in skranken og vis billeterne",
      showTicketsOnSmartphone: "Du behøver ikke at printe dine billetter ud. Vis dem blot fra din smartphone hvis muligt",
      signUp: "Tilmeld mig",
      signUpToTicketNotifications: "Tilmeld dig billetnotifikationer",
      SMSSentTo: "En SMS er blevet sendt til",
      SMSTicket: "SMS-billet",
      soldOut: "Udsolgt",
      startOver: "Start forfra",
      subscribed: "TILMELDT!",
      subscribingToTicketNotifications: "Tak fordi du tilmeldte dig billetnotifikationer",
      table: "Bord",
      temporarilySoldOut: "Midlertidigt udsolgt",
      termsAndConditions: "Vilkår og Betingelser",
      theOrganiser: "arrangøren",
      ticket: "Billet",
      ticketArea: "Billetområde",
      ticketDiscountCode: "Billet rabatkode",
      ticketFees: "Billetgebyr",
      ticketingPoweredBy: "Billetsystem leveret af",
      tickets: "Billetter",
      ticketSaleClosed: "Billet salg er lukket",
      ticketSaleEitherClosedOrNotStarted: "Billetsalget er enten afsluttet eller ikke startet endnu",
      ticketSalesNotStarted: "Billetsalget er ikke startet endnu",
      ticketSalesStart: "Billetsalget starter",
      ticketSalesStarts: "Billetsalget starter den",
      ticketsMightBecomeAvailable: "Der bliver muligvis frigivet flere billetter, så tjek siden igen inden for 15 minutter.",
      ticketsSentOnEmail: "Billetten sendes altid på email",
      ticketsWillBeSentTo: "Billetterne vil blive sendt til",
      ticketWillBeSentTo: "Billetten vil blive sendt til",
      timeIsUp: "Tiden er udløbet",
      toCompleteOrder: "til at gennemføre din ordre",
      toCompleteRegistration: "til at gennemføre din registrering",
      total: "i alt",
      totalPrice: "Total pris",
      totalReservations: "Reservationer i alt",
      tryAgain: "Prøv igen",
      transactionFee: "Transaktionsgebyr",
      viewOrderSummary: "Se ordreoversigt",
      viewRegistrationSummary: "Se registreringsoversigt",
      withinAFewMinutes: "inden for få minutter",
      yes: "Ja",
      youAlreadyHaveTicket: "Du har allerede en billet til dette event. Klik for at fortsætte din registrering alligevel",
      youHave: "Du har",
      yourRegistrationWasCancelled: "Din registrering annulleret",
      phewApiNotRespondingTryLater: "Pyha. Vores servere kæmper lidt med at vise dette indhold lige nu. Opdatér siden igen om 10-15 minutter, så skulle de gerne ha’ fået pusten igen!",
      selectOne: "Vælg en",
      noAnswerProvided: "Intet svar givet"
    }
  }
};
import i18n from "../../web_modules/i18next.js";
import { initReactI18next } from "../../web_modules/react-i18next.js";
import LanguageDetector from "../../web_modules/i18next-browser-languagedetector.js";
import en from "./translations/en.js";
import da from "./translations/da.js";
const resources = {
  en,
  da
};
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ["navigator"]
  }
});
export default i18n;